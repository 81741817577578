<template>
    <div class="item-card">
        <div class="image">
            <!-- <img v-if="item.image" :src="item.image"> -->
            <!-- <v-avatar v-if="item.image" :image="item.image" color="surface-variant" size="80"></v-avatar> -->
            <img v-show="item.image && img_loaded" @load="handle_img_load" :src="item.image" alt="תמונת פריט">
            <img v-if="!img_loaded" :src="no_item">
        </div>
        <div class="content-details">
            <div class="details">
                <el-tooltip
                        class="box-item"
                        effect="dark"
                        :content="item.name"
                        placement="top-start"
                    >
                    <p class="item-text truncate">{{ item.name }}</p>
                </el-tooltip>
            </div>
        </div>
        <p style="text-align: center; color: lightgray;">כמות במארז {{ item.pac_amount }} יח'</p>
        <p style="text-align: center; color: lightgray;"> מחיר ליח' <span :style="{'text-decoration': price_per_item!=item.unit_price?'line-through':'unset'}">{{ Number(item.unit_price).toFixed(2) }}</span> &#8362;</p>
        <p v-if="price_per_item!=item.unit_price" style="text-align: center; color: #23d5ab; font-weight: 500;"> מחיר ליח' {{ Number(price_per_item).toFixed(2) }} &#8362;</p>
        
        
        <div class="content-qnt">
            <el-input-number  @focus="item.qnt=undefined" v-model="item.qnt"  :min="0" :step="1" />
        </div>
        <div class="description">
            {{item.description}}
        </div>
       
        <p v-if="item.qnt!=undefined" style=" text-align: center;  font-size: 20px;">סה"כ: {{ Number(item.qnt) * Number(item.pac_amount) }} יח', {{((Number(item.qnt) * Number(item.pac_amount))*Number(price_per_item)).toFixed(2)}} &#8362;</p>
        <p v-else style=" text-align: center;  font-size: 20px;">סה"כ: x יח', x &#8362;</p>
    
        <div class="item-id-float">
            <p>{{ item.barcode }}</p>
        </div>
    </div>
</template>

<script>
import { computed, ref} from "vue";
import no_item from "../../../assets/images/defualt_photo.png";
export default {
    props:['item'],
    setup(props) {
        
     
        props.item.qnt = 0

        const img_loaded = ref(false)

        const price_per_item = computed(() => {
            let price = Number(props.item.unit_price) 
            if(props.item.is_deal){
    
                // Sort the dealDetails array in descending order of amount
                const sortedDeals = [...props.item.deal_datails].sort((a, b) => b.amount - a.amount);
    
                // Find the correct price based on the quantity
                for (let deal of sortedDeals) {
                    if (props.item.qnt >= deal.amount) {
                        price = deal.discount_price;
                        break;
                    }
                }
            }

            return price
        });


        const handle_img_load = ()=>{
            img_loaded.value = true
        }


     

      
        const color_mark = ref(computed(() => {
            if (props.item?.qnt > 0) return '#23d5ab'
            return 'lightblue'
        }))

        const border_mark = ref(computed(() => {
            if (props.item?.qnt > 0) return '3px'
            return '1px'
        }))

      

        return {
            handle_img_load,
            img_loaded,
            color_mark, 
            no_item,
            border_mark,
            price_per_item
        }
    }
}
</script>

<style scoped>
    .item-card{
        position: relative;
        width: 100%;
        border: v-bind(border_mark) solid v-bind(color_mark);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }
    .item-card:hover{
        border: 3px solid v-bind(color_mark);
    }
    .item-card .image{
        position: relative;
        width: 100%;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .item-card .image img{
        max-width: 50%;
        max-height: 100%;
    }

    .item-card > .content-details{
        width: 100%;
        font-size: 20px;
        padding: 0 5px;
    }

    .item-card > .content-details > .details{
        width: 100%;
        height: 100%;
        padding: 0 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .item-card > .content-qnt{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
    .item-card > .description{
        width: 100%;
        padding: 5px;
        text-align: center;
        margin-top: auto;
    }

    .item-id-float,
    .item-unit-float {
        position: absolute;
        top: 1px;
        left: 5px;
        z-index: 1;
        width: fit-content;
        height: fit-content;
        color: gray;
    }

    .item-id-float p,
    .item-unit-float p {
        font-weight: 400;
    }

    .item-unit-float {
        left: unset;
        right: 5px;
    }

    .truncate {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
    }

    .up-down-btn {
        background: var(--main);
        color: #fff;
    }

</style>