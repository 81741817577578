import { projectFirestore } from "../../../firebase/config";

const get_sales_form_db = async() => {
    try{
        const docs = await projectFirestore.collection('Shlush').doc('System')
        .collection('Sales').get()
        const sales = docs.docs.map(doc => doc.data())
        return sales
    }catch(err){
        throw new Error(err)
    }
}

const add_sale_to_db = async(new_sale) => {
    try{
        const doc = projectFirestore.collection('Shlush').doc('System').collection('Sales').doc()
        await doc.set({
            ...new_sale,
            uid:doc.id
        })
    }catch(err){
        throw new Error(err)
    }
}
const edit_sale_to_db = async(sale) => {
    try{
        await projectFirestore.collection('Shlush').doc('System').collection('Sales')
        .doc(sale.uid).set(sale,{merge:true})
    }catch(err){
        throw new Error(err)
    }
}

const delete_sale_from_db = async(sale) => {
    try{
        await projectFirestore.collection('Shlush').doc('System').collection('Sales')
        .doc(sale.uid).delete()
    }catch(err){
        throw new Error(err)
    }
}

const price_per_item = (item) => {
    let price = Number(item.unit_price) 
    if(item.is_deal){
        // Sort the dealDetails array in descending order of amount
        const sortedDeals = item.deal_datails.sort((a, b) => Number(b.amount) - Number(a.amount));
        // Find the correct price based on the quantity
        for (let deal of sortedDeals) {
            if (Number(item.qnt) >= Number(deal.amount)) {
                price = Number(deal.discount_price);
                break;
            }
        }
    }
    return price
}


export{
    get_sales_form_db,
    add_sale_to_db,
    edit_sale_to_db,
    delete_sale_from_db,
    price_per_item
}